import './PlaylistNamer.css';
import React, {useState} from "react";
import {ApiError, ErrorCode} from "../../../../../api/ApiError";
import {cancelAnalysis, createCombinatorialPlaylist} from "../../../../../api/distributor/DistributeApi";
import {ErrorMessage} from "../../../../common/ErrorMessage";
import {Criterion, isCriterionEmpty} from "../Criterion";
import {SmallerCancelButton, SmallerConfirmationButton} from "../../../../common/Buttons";

interface PlaylistNamerProps {
    criteria: Criterion[];
    goToOverview: () => void;
}

export const PlaylistNamer = (props: PlaylistNamerProps) => {
    const [errorCode, setErrorCode] = useState<ErrorCode>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [playlistName, setPlaylistName] = useState<string>("");
    const [createPlaylistsInProgressOfStarting, setCreatePlaylistsInProgressOfStarting] = useState<boolean>(false);

    return (
        <div className="playlistNamer">
            <p>What would you like to name your playlist?</p>
            <div className="userInputContainer">
                <input placeholder="Playlist name" onChange={(evt) => playlistNameInputChanged(evt)} className="userInput" type="text" value={playlistName}/>
            </div>
            <ErrorMessage errorCode={errorCode} errorMessage={errorMessage}/>
            <div className="wizardButtonContainer">
                <SmallerCancelButton onClick={() => props.goToOverview()} text={"Back to overview"}/>
                <ButtonSplitter/>
                <SmallerConfirmationButton onClick={() => createPlaylist()} text={"Create playlist"}/>
            </div>
        </div>
    )

    function createPlaylist(): boolean {
        if (createPlaylistsInProgressOfStarting) {
            return false;
        }

        setErrorCode(undefined);
        setErrorMessage(undefined);
        setCreatePlaylistsInProgressOfStarting(true);

        if (playlistName.length === 0) {
            setCreatePlaylistsInProgressOfStarting(false);
            setErrorMessage("You need to give your playlist a name.");
            return false;
        }
        
        let noneEmptyCriterion = props.criteria.filter(criterion => !isCriterionEmpty(criterion));
        if (noneEmptyCriterion.length === 0) {
            setCreatePlaylistsInProgressOfStarting(false);
            setErrorMessage("You have not added any criteria for your playlist.");
            return false;
        }
        
        let analysisProgressId = sessionStorage.getItem("analysisProgressId");
        if (analysisProgressId !== null) {
            cancelAnalysis(analysisProgressId)
                .catch((error: ApiError) => setErrorCode(error.errorCode))
                .then(() => doCreateCombinatorialPlaylist())
                .catch(() => setCreatePlaylistsInProgressOfStarting(false));
        } else {
            doCreateCombinatorialPlaylist();
        }

        return false;
    }

    function doCreateCombinatorialPlaylist() {
        let noneEmptyCriterion = props.criteria.filter(criterion => !isCriterionEmpty(criterion));
        let request = {
            combinations: [
                {
                    playlistName: playlistName,
                    criteria: noneEmptyCriterion
                }
            ]
        };

        createCombinatorialPlaylist(request)
            .then((distributionProgressId: string) => {
                window.location.replace(`/playlists/combinations/success?distributionProgressId=${distributionProgressId}&playlistName=${playlistName}`)
                return true;
            })
            .catch((error: ApiError) => setErrorCode(error.errorCode))
            .finally(() => setCreatePlaylistsInProgressOfStarting(false));
    }

    function playlistNameInputChanged(e: React.FormEvent<HTMLInputElement>) {
        let value: string = e.currentTarget.value;
        if (value.length > 30)
            return;

        if (value.length > 0 && value.match(("^[a-zA-Z0-9 ]+$")) === null)
            return;

        setPlaylistName(e.currentTarget.value);
    }
}

const ButtonSplitter = () => {
    return (
        <div className="buttonSplitter"></div>
    )
}

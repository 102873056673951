import './Wizard.css';
import {Playlist} from "../Playlist";
import React, {useState} from "react";
import {PlaylistNamer} from "./PlaylistNamer";
import {Criterion, isCriterionEmpty} from "../Criterion";
import {CriterionCreator} from "./CriterionCreator";

interface WizardProps {
    availableDecadePlaylists: Playlist[];
    availableGenrePlaylists: Playlist[];
    goToOverview: () => void;
}

const MaximumCriteria: number = 20;
export const Wizard = (props: WizardProps) => {
    const [criteria, setCriteria] = useState<Criterion[]>([{
        criterionNumber: 1,
        genres: [],
        decade: undefined
    }]);
    const [activeCriterionCreator, setActiveCriterionCreator] = useState<number>(1);

    let criterionCreators = Array.from(criteria, (criterion) => {
        return (
            <CriterionCreator
                activeCriterionCreator={activeCriterionCreator}
                setAsActiveCriterionCreator={(criterionNumber) => setActiveCriterionCreator(criterionNumber)}
                key={criterion.criterionNumber}
                criterionNumber={criterion.criterionNumber}
                availableGenrePlaylists={props.availableGenrePlaylists}
                availableDecadePlaylists={props.availableDecadePlaylists}
                addGenre={addGenre}
                removeGenre={removeGenre}
                addDecade={addDecade}
                removeDecade={removeDecade}
                criterion={criterion}
            />
        )
    });

    return (
        <div>
            <div className={"combinationPlaylistCreator"}>
                <div className="combinationPlaylistCreatorHeader">
                    <div className="combinationPlaylistCreatorHeaderText">Songs in this playlist must be</div>
                </div>
                {criterionCreators}
            </div>
            <PlaylistNamer criteria={criteria} goToOverview={props.goToOverview}/>
        </div>
    )

    function addGenre(criterionNumber: number, genre: string) {
        let oldCriterion = criteria.find(criterion => criterion.criterionNumber === criterionNumber)!;
        oldCriterion.genres.push(genre);
        setCriteria(criteria.map(criterion =>
            criterion.criterionNumber === criterionNumber
                ? oldCriterion!
                : criterion));

        initializeNewCriterionIfNeeded(criterionNumber);
    }

    function removeGenre(criterionNumber: number, genre: string) {
        let newCriteria = criteria
            .map(criterion => {
                if (criterion.criterionNumber !== criterionNumber) {
                    return criterion;
                }

                return {
                    ...criterion,
                    genres: criterion.genres.filter((oldGenre) => oldGenre !== genre)
                };
            });

        newCriteria = removeEmptyCriterion(newCriteria);
        setCriteria(newCriteria);
    }

    function addDecade(criterionNumber: number, decade: number) {
        let oldCriterion = criteria.find(criterion => criterion.criterionNumber === criterionNumber)!;
        oldCriterion.decade = decade;
        setCriteria(criteria.map(criterion =>
            criterion.criterionNumber === criterionNumber
                ? oldCriterion!
                : criterion));

        initializeNewCriterionIfNeeded(criterionNumber);
    }

    function removeDecade(criterionNumber: number) {
        let newCriteria = criteria
            .map(criterion => {
                if (criterion.criterionNumber !== criterionNumber) {
                    return criterion;
                }

                return {
                    ...criterion,
                    decade: undefined,
                };
            });

        newCriteria = removeEmptyCriterion(newCriteria);
        setCriteria(newCriteria);
    }

    function initializeNewCriterionIfNeeded(criterionNumber: number) {
        if (criterionNumber >= criteria.length && criteria.length < MaximumCriteria) {
            let newCriterion = {
                criterionNumber: criterionNumber + 1,
                decade: undefined,
                genres: []
            };

            setCriteria((criteria: Criterion[]) => [...criteria, newCriterion]);
        }
    }


    function removeEmptyCriterion(criteria: Criterion[]): Criterion[] {

        let newCriteria = criteria.filter(criterion => {
            let isFirstCriterion = criterion.criterionNumber === 1;
            if (isFirstCriterion && criteria.length === 1) {
                return true;
            }

            let isLastCriterion = criterion.criterionNumber === criteria[criteria.length - 1].criterionNumber;
            if (isLastCriterion) {
                return true;
            }

            return !isCriterionEmpty(criterion);
        });

        newCriteria = newCriteria.map((criterion, idx) => {
            return {
                ...criterion,
                criterionNumber: idx + 1,
            };
        });

        return newCriteria;
    }
}
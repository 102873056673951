import React from "react";
import {Criterion} from "./Criterion";
import './PrettifyCombination.css';

interface CombinationExpressionProps {
    criteria: Criterion[];
}

export const CombinationExpression = (props: CombinationExpressionProps) => {
    let atLeastThreeCriterion = props.criteria.length >= 3;
    let criteria = props.criteria.map((criterion, index) => createCriterion(criterion, index));

    return (
        <span>
            <span>Must be</span>
            {criteria.map((criterion, index) => {
                return <React.Fragment key={`criterion--${index}`}>
                    <span>{criterion.beginsWithVowel ? ' an ' : ' a '}</span>
                    {criterion.criterion}
                    {index + 1 !== criteria.length
                        ? <span>{atLeastThreeCriterion ? ', or ' : ' or '}</span>
                        : ''
                    }
                </React.Fragment>
            })}
        </span>
    )
}

const createCriterion = (criterion: Criterion, index: number): CriterionWrapper => {
    let genres = criterion.genres;
    let firstLetterVowel = criterion.genres.length > 0 && beginsWithVowel(criterion.genres[0]);

    let elements: React.ReactElement[] = [];
    for (let i = 0; i < genres.length; i++) {
        let genre = genres[i];
        let key = `criterion--genre--${index}--${genre.toLowerCase()}`;
        if (genres.length === 1) {
            elements.push(<span key={key} className={"combinationGenre"}>{genre.toLowerCase()}</span>);
        } else if (i + 1 === genres.length) {
            elements.push(<React.Fragment key={key}>
                <span> and </span>
                <span className={"combinationGenre"}>{genre.toLowerCase()}</span>
            </React.Fragment>);
            // Only add comma between genres if at least 3 genres
        } else if (genres.length >= 3) {
            elements.push(<React.Fragment key={key}>
                <span className={"combinationGenre"}>{genre.toLowerCase()}</span>
                <span>, </span>
            </React.Fragment>);
        } else {
            elements.push(<span key={key} className={"combinationGenre"}>{genre.toLowerCase()}</span>);
        }
    }

    if (criterion.decade !== undefined) {
        elements.push(<React.Fragment key={`criterion--decade--${index}}`}>
            <span> song from the </span>
            <span className={"combinationDecade"}>{criterion.decade}s</span>
        </React.Fragment>);
    } else {
        elements.push(<span key={`criterion--end--${index}}`}> song</span>);
    }


    return {
        criterion: <>{elements}</>,
        beginsWithVowel: firstLetterVowel
    }
}

interface CriterionWrapper {
    criterion: React.ReactElement;
    beginsWithVowel: boolean;
}

const vowels = ("aeiouAEIOU");
const beginsWithVowel = (val: string): boolean => {
    return vowels.indexOf(val[0]) !== -1;
}
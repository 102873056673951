import React from 'react';
import {GoToLoginButton} from '../common/Buttons';
import Footer from "../../footer/Footer";
import './LandingPage.css';
import {ReactComponent as Dice} from './management/common/dice.svg';
import {ReactComponent as Calendar} from './management/common/calendar.svg';
import {ReactComponent as Clock} from './management/common/clock.svg';
import {ReactComponent as Globe} from './management/common/globe.svg';
import {ReactComponent as Music} from './management/common/music.svg';
import {ReactComponent as Smile} from './management/common/smile.svg';
import {ReactComponent as Dots} from './management/common/dots.svg';
import {ReactComponent as History} from './management/common/history.svg';
import {ReactComponent as Star} from './management/common/star.svg';


import {PlaylistAnimation} from "./PlaylistAnimation";
import {FaqQuestion} from "../faq/Faq";
import {Link} from "react-router-dom";

export const LandingPage = () => {
    return (
        <React.Fragment>
            <div id="pageContainer">
                <div id="pageContent">
                    <div>
                        <div className="pageContentSegment landingPageIntro">
                            <h1>Automate your Spotify playlist management</h1>
                            <div className={"introDesktop"}>
                                <div className={"introDesktopTextAndAnimation"}>
                                    <div className={"introText"}>
                                        <p>Liked Songs Manager makes playlists from your Spotify liked songs based on genre, mood, decade, nationality, and more.</p>
                                        <p>You choose which playlists will show up in Spotify, and then this application creates them and keeps them up to date as you like new
                                            songs.</p>
                                        <GoToLoginButton/>
                                    </div>
                                    <PlaylistAnimation/>
                                </div>
                            </div>
                            <div className={"introMobile"}>
                                <div className={"introText"}>
                                    <p>Liked Songs Manager makes playlists from your Spotify liked songs based on genre, mood, decade, nationality, and more.</p>
                                    <p>You choose which playlists will show up in Spotify, and then this application creates them and keeps them up to date as you like new
                                        songs.</p>
                                </div>
                                <div className={"loginButtonMobile"}>
                                    <GoToLoginButton noMarginTop={true}/>
                                </div>
                                <PlaylistAnimation/>
                            </div>
                        </div>
                        <div className="pageContentSegment">
                            <div>
                                <h2>Managing your playlists has never been easier</h2>
                                <p className="introText">No more choosing between a jumble of liked songs or tedious playlist editing. Unlike genre filters for liked songs in
                                    Spotify, managed playlists
                                    work for desktop playback, sharing, and bulk playlist editing.</p>
                                <div className={"playlistsContainer"}>
                                    <FreePlaylists/>
                                    <PremiumPlaylists/>
                                </div>
                            </div>
                        </div>
                        <div className="pageContentSegment">
                            <div>
                                <div className={"combinationPlaylistsHeaderContainer"}>
                                    <h2 className={"combinationPlaylistsHeader"}>Combination playlists</h2>
                                    <Star className={"combinationPlaylistsHeaderStar"} width={16} height={16}/>
                                </div>
                                <div className={"combinationPlaylistContainer"}>
                                    <div className={"combinationGraphic"}>All of your songs of [favorite genre] which are also of [other favorite genre] from [decade].</div>
                                    <div className={"introText"}>
                                        It is even possible to create a combinations of filters to fit your exact needs. Want a playlist of all your J-Pop and Rap made in the
                                        2000s? No
                                        issue!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="pageContentSegment">
                            <div>
                                <h2>Frequently Asked Questions</h2>
                                <FaqQuestion
                                    question={"Can I rename playlists created by Liked Songs Manager?"}
                                    answer={<p>Yes, you can rename your playlists to whatever you want, they will still be kept up to date by Liked Songs Manager. This includes
                                        your
                                        combination playlists as well.</p>}/>
                                <FaqQuestion
                                    question={"How do I cancel my Premium subscription?"}
                                    answer={<div>
                                        <p>If you wish to cancel your Premium subscription go to <a href="/plans">Plans</a>.</p>
                                        <p>Note that you even if you cancel your Premium subscription, you will still keep your Premium subscription for the remainder of the
                                            subscription
                                            period.</p>
                                    </div>}/>
                                <FaqQuestion
                                    question={"Can I put playlists created by Liked Songs Manager into folders?"}
                                    answer={<p>Yes, you can put your playlists into folders, they will still be kept up to date by Liked Songs Manager.</p>}/>
                                <FaqQuestion
                                    question={"How often are my liked songs checked for updates?"}
                                    answer={<p>Your songs are checked continuously, about every hour.</p>}/>
                                <FaqQuestion
                                    question={"If I remove a song from a playlist, will it get re-added?"}
                                    answer={<p>The song will not be re-added to the playlist.</p>}/>
                                <FaqQuestion
                                    question={"Will Liked Songs Manager remove the songs which I have removed from my liked songs from the managed playlists?"}
                                    answer={
                                        <React.Fragment>
                                            <p>Unfortunately to properly support this, it would be necessary to fetch every user's entire library of liked songs every time Liked
                                                Songs Manager
                                                checks whether there are new songs. This would lead to API throttling by Spotify, and is therefore not employed.</p>
                                            <p>If you wish to recreate the playlists from scratch, this be achieved by deleting the playlist, and then creating it again.</p>
                                        </React.Fragment>
                                    }/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </React.Fragment>
    )
}

const iconSize = 30;
const FreePlaylists = () => {
    return (
        <div className="playlists">
            <Playlist premium={false} group={"Mood"} examples={"Chill, Party, Upbeat"} groupPath={"mood"} icon={<Smile height={iconSize} width={iconSize}/>}/>
            <Playlist premium={false} group={"Genre and subgenre"} examples={"Rock, J-Pop, Black Metal"} groupPath={"genre"} icon={<Music height={iconSize} width={iconSize}/>}/>
            <Playlist premium={false} group={"Decade"} examples={"1950s, 1960s, 1970s"} groupPath={"decade"} icon={<Calendar height={iconSize} width={iconSize}/>}/>
            <Playlist premium={false} group={"Nationality"} examples={"British, French, German"} groupPath={"nationality"} icon={<Globe height={iconSize} width={iconSize}/>}/>
        </div>
    )
}


const PremiumPlaylists = () => {
    return (
        <div className="playlists premium">
            <Playlist premium={true} group={"Liked At"} examples={"Liked in Year, Liked in Month"} groupPath={"liked-at"} icon={<History height={iconSize} width={iconSize}/>}/>
            <Playlist premium={true} group={"Randomized Playlists"} examples={"50 Daily Random Songs"} groupPath={"randomized"} icon={<Dice height={iconSize} width={iconSize}/>}/>
            <Playlist premium={true} group={"Latest Liked Songs"} examples={"Latest 50 Liked Songs"} groupPath={"latest-liked-songs"} icon={<Clock height={iconSize} width={iconSize}/>}/>
            <Playlist premium={true} group={"Miscellaneous"} examples={"Everything, Partitions"} groupPath={"miscellaneous"} icon={<Dots height={iconSize} width={iconSize}/>}/>
        </div>
    )
}

interface PlaylistProps {
    group: string;
    groupPath: string;
    icon: React.ReactElement
    examples: string;
    premium: boolean;
}

const Playlist = (props: PlaylistProps) => {
    return (
        <Link className={"playlistGroupLink"} to={`/playlists/${props.groupPath}`}>
            <div className="playlist">
                <div className="playlistHeaderContainer">
                    <h3 className="playlistHeader">
                        {props.group}
                    </h3>
                    {props.premium && <div className={"playlistPremiumIcon"}>
                        <Star/>
                    </div>
                    }
                </div>
                <div className="playlistIcon">
                    {props.icon}
                </div>
                <div className={"playlistExamples"}>{props.examples}, ..</div>
            </div>
        </Link>
    )
}

import {Playlist} from "../Playlist";
import {Criterion} from "../Criterion";
import React, {useRef, useState} from "react";
import {ValuePickerButton} from "./ValuePickerButton";
import {Selector} from "./Selector";
import {ReactComponent as Music} from '../../common/music.svg';
import {ReactComponent as Calendar} from '../../common/calendar.svg';
import {SubgenreSearch} from "../../common/SubgenreSearch";
import {AllSpotifyGenres} from "../../../SpotifyGenres";

interface CriterionCreatorProps {
    criterionNumber: number;
    addGenre: (criterionNumber: number, genre: string) => void;
    removeGenre: (criterionNumber: number, genre: string) => void;
    addDecade: (criterionNumber: number, decade: number) => void;
    removeDecade: (criterionNumber: number) => void;
    availableGenrePlaylists: Playlist[];
    availableDecadePlaylists: Playlist[];
    criterion: Criterion;
    setAsActiveCriterionCreator: (criterionNumber: number) => void;
    activeCriterionCreator: number;
}

export const CriterionCreator = (props: CriterionCreatorProps) => {
    const [genrePickerShown, setGenreSelectorShown] = useState<boolean>(false);
    const [subgenrePickerShown, setSubgenrePickerShown] = useState<boolean>(false);
    const [decadePickerShown, setDecadeSelectorShown] = useState<boolean>(false);
    const addDecadeButtonRef = useRef(null);
    const addSubgenreButtonRef = useRef(null);
    const addGenreButtonRef = useRef(null);

    let isNotFirstCriterion = props.criterionNumber > 1;
    let removeGenreButtons = props.criterion.genres.map((
        (genre, buttonNumber) => <RemoveGenreButton
            criterionNumber={props.criterionNumber}
            removeGenre={props.removeGenre}
            key={`${props.criterionNumber}-${genre}`}
            genre={genre}
            buttonNumber={buttonNumber}

        />));

    let isTheActiveCriterionCreator = props.activeCriterionCreator === props.criterionNumber;

    let canAddMoreGenres = props.criterion.genres.length < 4;
    let canAddDecade = props.criterion.decade === undefined;
    let remainingAvailableGenrePlaylists = props.availableGenrePlaylists.filter((playlist => props.criterion.genres.indexOf(playlist.name) === -1))

    let defaultButtonClasses = "wizardAddButton unselectable";
    let genreButtonClasses = defaultButtonClasses
    if (genrePickerShown && isTheActiveCriterionCreator) {
        genreButtonClasses += " active";
    }

    let decadeButtonClasses = defaultButtonClasses
    if (decadePickerShown && isTheActiveCriterionCreator) {
        decadeButtonClasses += " active";
    }

    let subgenreButtonClasses = defaultButtonClasses
    if (subgenrePickerShown && isTheActiveCriterionCreator) {
        subgenreButtonClasses += " active";
    }


    return (
        <div className="criterionCreatorContainer">
            {isNotFirstCriterion && <div className="criterionCreatorHeader">or </div>}
            <div className="criterionCreator">
                <div className="criterionButtons">
                    <div className="criterionRemoveButtons">
                        {removeGenreButtons}
                        {!canAddDecade &&
                            <RemoveDecadeButton
                                criterionNumber={props.criterionNumber}
                                decade={props.criterion.decade!}
                                removeDecade={props.removeDecade}/>
                        }
                    </div>
                    <div className="criterionAddButtons">
                        {canAddMoreGenres &&
                            <>
                                <div ref={addGenreButtonRef} onClick={addGenreClick} className={genreButtonClasses}>
                                    Genre
                                </div>
                                <div ref={addSubgenreButtonRef} onClick={addSubgenreClick} className={subgenreButtonClasses}>
                                    Subgenre
                                </div>
                            </>
                        }
                        {canAddDecade &&
                            <div ref={addDecadeButtonRef} onClick={addDecadeClick} className={decadeButtonClasses}>
                                Decade
                            </div>
                        }
                    </div>
                </div>
                <div className="criterionSelectors">
                    {genrePickerShown && isTheActiveCriterionCreator && <GenreSelector
                        addGenre={addGenre}
                        addGenreButtonRef={addGenreButtonRef}
                        criterionNumber={props.criterionNumber}
                        availableGenrePlaylists={remainingAvailableGenrePlaylists}
                        hide={() => setGenreSelectorShown(false)}/>}
                    {decadePickerShown && isTheActiveCriterionCreator && <DecadeSelector
                        addDecade={addDecade}
                        addDecadeButtonRef={addDecadeButtonRef}
                        hide={() => setDecadeSelectorShown(false)}
                        criterionNumber={props.criterionNumber}
                        availableDecadePlaylists={props.availableDecadePlaylists}/>}
                    {subgenrePickerShown && isTheActiveCriterionCreator && <SubgenreSelector
                        addSubgenre={addSubgenre}
                        addSubgenreButtonRef={addSubgenreButtonRef}
                        hide={() => setSubgenrePickerShown(false)}
                        selectedSubgenres={props.criterion.genres}
                        criterionNumber={props.criterionNumber}/>}
                </div>
            </div>
        </div>
    )

    function addSubgenreClick() {
        let wasShown = subgenrePickerShown;
        closeAllSelectors();
        setSubgenrePickerShown(!wasShown);
    }

    function addGenreClick() {
        let wasShown = genrePickerShown;
        closeAllSelectors();
        setGenreSelectorShown(!wasShown);
    }

    function addDecadeClick() {
        let wasShown = decadePickerShown;
        closeAllSelectors();
        setDecadeSelectorShown(!wasShown);
    }

    function closeAllSelectors() {
        props.setAsActiveCriterionCreator(props.criterionNumber);
        setDecadeSelectorShown(false);
        setGenreSelectorShown(false);
        setSubgenrePickerShown(false);
    }

    function addGenre(criterionNumber: number, genre: string) {
        setGenreSelectorShown(false)
        props.addGenre(criterionNumber, genre);
    }

    function addSubgenre(criterionNumber: number, genre: string) {
        setSubgenrePickerShown(false)
        props.addGenre(criterionNumber, genre);
    }

    function addDecade(criterionNumber: number, decadePlaylistName: string) {
        setDecadeSelectorShown(false);
        let decade = Number(decadePlaylistName.replace("s", ""));
        props.addDecade(criterionNumber, decade);
    }
}

interface RemoveGenreButtonProps {
    criterionNumber: number;
    genre: string;
    buttonNumber: number;
    removeGenre: (criterionNumber: number, genre: string) => void;
}

const RemoveGenreButton = (props: RemoveGenreButtonProps) => {
    let isNotFirstButton = props.buttonNumber > 0;

    return (
        <div className="wizardRemoveButtonContainer">
            {isNotFirstButton && <div className="wizardRemoveButtonPretext">and also</div>}
            <div onClick={() => props.removeGenre(props.criterionNumber, props.genre)} className={"wizardRemoveButton unselectable"}>
                {props.genre}
            </div>
        </div>
    )
}

interface RemoveDecadeButtonProps {
    criterionNumber: number;
    decade: number;
    removeDecade: (criterionNumber: number) => void;
}

const RemoveDecadeButton = (props: RemoveDecadeButtonProps) => {
    return (
        <div className="wizardRemoveButtonContainer">
            <div className="wizardRemoveButtonPretext">from the</div>
            <div onClick={() => props.removeDecade(props.criterionNumber)} className={"wizardRemoveButton unselectable"}>
                {props.decade}s
            </div>
        </div>
    )
}

interface GenreSelectorProps {
    availableGenrePlaylists: Playlist[];
    criterionNumber: number;
    addGenre: (criterionNumber: number, genre: string) => void;
    hide: () => void;
    addGenreButtonRef: any;
}

const GenreSelector = (props: GenreSelectorProps) => {
    let genreButtons = props.availableGenrePlaylists
        .sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true}))
        .map(genre => <ValuePickerButton
            criterionNumber={props.criterionNumber}
            addValue={props.addGenre}
            key={genre.name}
            value={genre.name}/>);

    return <Selector
        addButtonRef={props.addGenreButtonRef}
        header={"Pick a genre"}
        hide={props.hide}
        criterionNumber={props.criterionNumber}
        buttons={genreButtons}
        icon={<Music height={24} width={24}/>}/>
}

interface DecadeSelectorProps {
    availableDecadePlaylists: Playlist[];
    criterionNumber: number;
    addDecade: (criterionNumber: number, decadePlaylistName: string) => void;
    hide: () => void;
    addDecadeButtonRef: any;
}

const DecadeSelector = (props: DecadeSelectorProps) => {
    let decadeButtons = props.availableDecadePlaylists
        .sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true}))
        .map(playlist => <ValuePickerButton
            criterionNumber={props.criterionNumber}
            addValue={props.addDecade}
            key={playlist.name}
            value={playlist.name}/>);

    return <Selector
        addButtonRef={props.addDecadeButtonRef}
        header={"Pick a decade"}
        hide={props.hide}
        criterionNumber={props.criterionNumber}
        buttons={decadeButtons}
        icon={<Calendar height={24} width={24}/>}/>
}

interface SubgenreSelectorProps {
    criterionNumber: number;
    addSubgenre: (criterionNumber: number, genre: string) => void;
    selectedSubgenres: string[];
    hide: () => void;
    addSubgenreButtonRef: any;
}

const SubgenreSelector = (props: SubgenreSelectorProps) => {
    let usingPopUpSelector = window.innerWidth > 1250;

    let subgenreSearch = <SubgenreSearch
        key={"subgenre-search-" + props.criterionNumber}
        addSelectedPreferences={addSubgenre}
        allSubgenres={AllSpotifyGenres}
        selectedPreferencesNames={props.selectedSubgenres}
        selectedPreferencesButtons={[]}
        mostRecentlySelected={undefined}
        roomForMorePreferences={true}
        tooManySelectedPreferencesText={""}
        height={usingPopUpSelector ? 216 : 341}
        analysisStarted={false}
        removeFilterTopMargin={true}/>

    return <Selector
        addButtonRef={props.addSubgenreButtonRef}
        header={"Pick a subgenre"}
        hide={props.hide}
        criterionNumber={props.criterionNumber}
        buttons={[subgenreSearch]}
        icon={<Music height={24} width={24}/>}/>

    function addSubgenre(genre: string) {
        props.addSubgenre(props.criterionNumber, genre);
    }
}